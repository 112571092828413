@import '../../styles/customMediaQueries.css';

/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  height: 100%;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--colorWhite);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.titleBookNow {
  color: var(--Black-Shade-White, #fff);
  font-family: Nunito;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding: 17px 0 12px;
  text-align: center;
  margin: 0;
  @media (max-width: 767px) {
    font-size: 22px;
    padding: 12px 0 12px;
  }
}
.orderCard {
  border-radius: 25px;
  background: var(--Black-Shade-White, #fff);
  box-shadow: 0px 6.25px 40px 0px rgba(0, 0, 0, 0.09);
  padding: 35px 24px 80px;
  & input,
  & select {
    border-radius: 15px !important;
    background: #f8fdff !important;
    border: none !important;
    color: var(--Black-Shade-Para_Text, #949494) !important;
    font-family: Nunito !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    height: 73px;
    padding-left: 48px;
    @media (max-width: 767px) {
      height: 55px;
      width: calc(100% - 12px);
      padding-left: 32px;
      font-size: 16px !important;
    }
    &:after{
      left: 15px;
        bottom: 25px;
    }
  }
  & button {
    height: 75px;
    @media (max-width: 767px) {
      height: 50px;
      font-size: 22px;
    }
  }
  & label {
    color: #7b7a7a;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &>div{
    overflow: unset !important;
  }
}
.modalHeading {
  margin-top: 89px;
  margin-bottom: 0px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.heading {
  /* Font */
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.price {
  composes: h4 from global;
  color: #7b7a7a;
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 0 24px;

  @media (--viewportMedium) {
    margin: 0;
    padding: 0;
  }
}

.perUnit {
  /* Font */
  composes: h4 from global;
  color: #7b7a7a;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
  }
}

.author {
  composes: p from global;
  margin: 0px 24px 32px 24px;

  display: flex;
  flex-direction: row;
  align-items: center;

  @media (--viewportMedium) {
    width: 100%;
    margin: 12px 0 36px 0;
    color: var(--colorGrey700);
  }
}

.providerAvatar {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    margin-right: 10px;
  }
}

.providerNamePlain {
  display: inline;

  @media (--viewportLarge) {
    display: none;
  }
}

.providerNameLinked {
  display: none;

  @media (--viewportLarge) {
    display: inline;
  }
}

.orderHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 0;
  }
}

.orderTitle {
  /* Font */
  color: var(--colorGrey700);

  margin-top: 0;
  margin-bottom: 2px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.orderHelp {
  composes: h5 from global;
  display: none;

  @media (--viewportMedium) {
    color: var(--colorGrey700);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.openOrderForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainerInCTA,
.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 4px;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 3px 0;

  @media (--viewportMedium) {
    padding: 4px 0;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    margin-right: unset;
    padding: 0;
  }
}
.priceContainerInCTA {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: unset;
}

.priceValue {
  /* Font */
  composes: h3 from global;
  color: var(--marketplaceColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnitInCTA {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
  }
}

.closedListingButton {
  border-left: 1px solid var(--colorGrey100);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.receipt {
  flex-shrink: 0;
}

.error {
  composes: h4 from global;
  color: var(--colorFail);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}
.errorSidebar {
  color: var(--colorFail);
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}
