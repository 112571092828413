.root {}

.select {
  color: var(--matterColorAnti);
  border: solid 1px var(--matterColorNegative);
  padding: 0px 15px;
  border-radius: 10px;
  height: 45px;
  padding-right: 40px;

  &>option {
    color: var(--matterColor);
  }

  &>option:disabled {
    color: var(--matterColorAnti);
  }
}

.selectSuccess {
  color: var(--matterColor);
  border-color: var(--successColor);
}

.selectError {
  border-color: var(--failColor);
}
.multiSelectBox{
  &>div{
    /* background: #FFFFFF; */
    border-radius: 10px;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    /* height: 48px; */
    color: #525961;
    border-color: rgb(82 89 97 / 80%);
    &>div{
      padding:5px 8px;
    }
    & input{
      height: auto;
      &:focus{
        box-shadow: unset;
      }
    }
  }
}