.customField {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
.multiSelect {
  margin-bottom: 24px;
  order: 6;
  & input {
    min-height: 32px !important;
    @media (max-width: 680px) {
      min-height: 28px !important;
    }
  }
  & > div {
    & > div {
      & > div {
        border: none !important;

        &:nth-child(3) {
          background: #f6f6f66e !important;
        }
      }
    }
  }
}
.radioWrapper {
  order: 4;
  margin-bottom: 10px;
  & label {
    font-family: Nunito;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.55px;
    text-align: left;
    color: #e28415 !important;
    & svg{
      transform: scale(1.5);
    }
  }
  & .radioField {
    display: flex;
    gap: 36px;
    & label {
      & span {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;
        line-height: 19.5px;
        text-align: left;
      }
    }
  }
}
