@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.link {
  display: inline-block;
  white-space: nowrap;
  /* Font */
  composes: textSmall from global;

  color: var(--Black-Shade-Para_Text, #949494);
  font-family: Nunito;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 10px;
  padding-top: 10px;
  height: 100%;

  /* push tabs against bottom of tab bar */
  margin-top: auto;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  transition: var(--transitionStyleButton);
  /* background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%234a4a4a' fill-rule='evenodd'/></svg>"); */
  background-position: calc(var(--TabNav_linkWidth) + 4px) center; /* SelectedLink's width (200px) + border thickness (4px) = 204px */

  &:hover {
    text-decoration: none;
    color: var(--colorBlack);
  }

  @media (--viewportLarge) {
    min-width: 350px;
    font-weight: 500;
    font-size: 28px;
    line-height: 24px;

    height: unset;
    width: var(--TabNav_linkWidth);
    border-bottom-width: 0px;
    margin-top: 0;
    margin-bottom: 16px;

    padding-top: 5px; /* Drop the text to adjust it to correct baseline */
    padding-bottom: 3px;

    &:hover {
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='35' viewBox='0 0 10 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='10' height='35' rx='5' fill='%233D70A1'/%3E%3C/svg%3E%0A");
      background-position: right center;
      background-size: 9px 35px;
    }
  }
}

.selectedLink {
  border-bottom-color: var(--colorBlack);
  font-family: Nunito;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #3d70a1;
  @media (max-width: 1023px) {
    font-size: 14px;
  }
  @media (--viewportLarge) {
    width: var(--TabNav_linkWidth);
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='35' viewBox='0 0 10 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='10' height='35' rx='5' fill='%233D70A1'/%3E%3C/svg%3E%0A");
    background-position: right center;
    background-size: 9px 35px;
    &:hover {
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='35' viewBox='0 0 10 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='10' height='35' rx='5' fill='%233D70A1'/%3E%3C/svg%3E%0A");
      background-position: right center;
      background-size: 9px 35px;
    }
  }
  &:after {
    content: unset !important;
  }
}

.disabled {
  pointer-events: none;
  color: var(--Black-Shade-Inactive, #dbdbdb);
  text-decoration: none;
  @media (min-width: 1024px) {
    font-family: Nunito;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
