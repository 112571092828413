@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  /* flex-direction: column; */
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  padding: 27px;
  margin-bottom: 2rem;
  /* Remove link's hover effect */
  @media (max-width: 1023px) {
    flex-direction: column;
  }
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  /* flex-direction: column; */
  /* padding: 24px; */
  width: 100%;
  @media (min-width: 1023px) {
    width: 38%;
  }
  @media (--viewportLarge) {
    display: flex;
    gap: 20px;
    border-left: 1px solid #dbdbdb;
    margin-left: 20px;
    padding-left: 24px;
  }
  & .mainInfo{
    width: 45%;
    @media (max-width: 1023px) {
      width: 100%;
    }
  }
  & .timingInfo{
    width: 55%;
    @media (max-width: 1023px) {
      width: 100%;
    }
  }
}

.category {
  border-radius: 45px;
  background: #dff8ff;
  display: inline-block;
  padding: 10px;
  margin: 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  color: var(--Apps-Apps_primary, #3D70A1);
  text-align: right;
  font-family: Nunito;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.feePriceValue{
  color: var(--Black-Shade-Text_Black, #272727);
font-family: Nunito;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  color: var(--Apps-Apps_primary, #3D70A1);
  text-align: right;
  font-family: Nunito;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.feePerUnit{
  color: var(--Black-Shade-Text_Black, #272727);
font-family: Nunito;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.title {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey700);
  margin: 0;
}

.authorInfo {
  color: #000;

  /* Web/Title-28 */
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (--viewportMedium) {
    font-size: 28px;
  }
}

.country {
  color: #949494;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  @media (--viewportMedium) {
    font-size: 22px;
  }
}

.title {
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: initial;  
  word-break: break-word;
  @media (--viewportMedium) {
    font-size: 22px;
  }
}

.description {
  color: #949494;
  text-align: justify;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-break: break-word;
  @media (--viewportMedium) {
    font-size: 18px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
.starIconGold {
  color: #e28415;
  font-size: 30px;
}
.starIconGrey {
  color: rgb(199, 199, 199);
  font-size: 30px;
}

.cardHeader {
  padding-bottom: 23px;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 20px;
  gap: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.priceLabel {
  color: #949494;

  text-align: right;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  @media (--viewportMedium) {
    font-size: 28px;
  }
}

.mainInfoWrapper {
  @media (--viewportLarge) {
    width: 65%;
  }
}

.profileInfo {
  display: flex;
  align-items: center;
  gap: 15px;
  @media (--viewportMedium) {
    gap: 27px;
  }
  & .aspectRatioWrapper {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    border: 2px solid #fff;
    overflow: hidden;
    @media (max-width: 767px) {
      width: 70px;
      height: 70px;
      min-width: 70px;
    }
    & img {
      object-fit: cover;
      width: 100px;
      height: 100px;
      border: 0;
      @media (max-width: 767px) {
        width: 70px;
        height: 70px;
        min-width: 70px;
      }
    }
  }
}

.price {
  color: #3d70a1;
  text-align: right;
  font-family: Nunito;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}
.label {
  color: #949494;
  padding: 0;
  text-transform: initial;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  @media (--viewportMedium) {
    font-size: 18px;
  }
}

.infoData {
  color: #272727;

  /* Web/Paragrap-18 */
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media (--viewportMedium) {
    font-size: 18px;
  }
  &::first-letter{
    text-transform: capitalize;
  }
}

.infoDataNoMargin {
  composes: infoData;
  /* margin-bottom: 0; */
}

.cardBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 28px;
  @media (max-width: 1023px) {
    margin-bottom: 20px;
  }
  @media (max-width: 576px) {
  flex-direction: column;
  gap: 20px;
  }
}

.buttonGroups {
  display: flex;
  align-items: center;
  gap: 27px;
}

.proposerBtn {
  border-radius: 45px;
  min-width: 242px;
  /* width: 100%; */
  background: #3d70a1;
  color: #fff;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  padding: 10px;
  @media (max-width: 1270px) {
    min-width: 160px;
    font-size: 16px;
  }
}

.saveBtn {
  min-width: 214px;
  /* width: 100%; */
  border-radius: 45px;
  border: 1px solid #3d70a1;
  background: #fff;
  color: #3d70a1;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: 1270px) {
    min-width: 110px;
    font-size: 16px;
  }
}

.postedDate {
  color: #949494;
  text-align: right;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
