@import '../../styles/customMediaQueries.css';

.root {
  display: block;

  flex-shrink: 0;
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    padding: 0 24px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}
.logoImageDesktop {
  width: 100%;
  height: 100%;
  max-width: 260px;
  max-height: 63px;
  object-fit: contain;
  @media (max-width: 1450px) {
    max-width: 220px;
  }
  @media (max-width: 767px) {
    max-width: 145px;
    max-height: 36px;
  }
}
