@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  /* Display the "check" when checked */
  &:checked + label .checked {
    display: inline;
  }

  /* Highlight the borders if the checkbox is hovered, focused or checked */
  &:hover + label .notChecked,
  &:hover + label .required,
  &:focus + label .notChecked,
  &:focus + label .required,
  &:checked + label .required {
    stroke: var(--colorBlack);
  }

  &:checked + label .notChecked {
    stroke: none;
  }

  /* Hightlight the text on checked, hover and focus */
  &:focus + label .text,
  &:hover + label .text,
  &:checked + label .text {
    color: var(--Web-Primary, #3d70a1);
  }
}

input[type='checkbox'] + label {
  /* display: block; */
  cursor: pointer;
  font-family: 'Arial';
}

input[type='checkbox'] {
  display: none;
}

input[type='checkbox'] + label:before {
  content: '\2714';
  border: 0.1em solid #b0b0b5;
  border-radius: 0.2em;
  display: inline-block;
  width: 16px;
  height: 16px;
  min-width: 16px;
  padding-left: 2px;
  padding-top: 0px;
  margin-right: 8px;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
  line-height: 16px;
}

input[type='checkbox'] + label:active:before {
  transform: scale(0);
}

input[type='checkbox']:checked + label:before {
  background: #3d70a1;
  border-color: #3d70a1;
  color: white;
}

input[type='checkbox']:disabled + label:before {
  transform: scale(1);
  border-color: #aaa;
}

input[type='checkbox']:checked:disabled + label:before {
  transform: scale(1);
  background-color: #f7c28f;
  border-color: #f7c28f;
}

.label {
  display: flex;
  align-items: center;
  padding-top: 1px;
  padding-bottom: 5px;

  @media (--viewportMedium) {
    padding-top: 0px;
    padding-bottom: 8px;
  }
}

.radioButtonWrapper {
  /* This should follow line-height */
  height: 24px;
  margin-top: -1px;
  margin-right: 10px;
  align-self: center;

  display: inline-flex;
  align-items: center;
  cursor: pointer;
  @media (max-width: 767px) {
    margin-right: 6px;
  }
}

.checkedStyle {
  stroke: transparent;
  fill: transparent;
  & circle {
    &:nth-child(1) {
      stroke: #3d70a1;
      stroke-width: 1;
    }
    &:nth-child(2) {
      fill: #3d70a1;
    }
  }
}

.checked {
  display: none;
}

.notChecked {
  stroke: #949494;
  stroke-width: 1px;
  &:hover {
    stroke: pink;
  }
}

.required {
  stroke: var(--colorAttention);
  &:hover {
    stroke: pink;
  }
}

.text {
  composes: marketplaceListingAttributeFontStyles from global;
  color: var(--colorGrey700);
  /* margin-top: -1px; */
  margin-bottom: 1px;
  cursor: pointer;
}
